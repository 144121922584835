@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
 
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
 
body {
    background: #EEF3F5;
    font-family: 'Open Sans', sans-serif;
}
 
#root {
    display: flex;
    justify-content: center;
}
 
a {
    text-decoration: none;
}
 
a:hover {
    text-decoration: underline;
}